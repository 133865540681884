import React, { useState, useContext, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { uuid } from "uuidv4"
import Modal from "react-modal"

import { CartContext } from "../context/cartContext"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Terms from "../components/terms"
import CanvasTypeButton from "../components/canvasTypeButton"

import CartIcon from "../images/svg/cart.svg"
import InfoIcon from "../images/svg/info.svg"

import { calculatePrice } from "../utils/calculator"

const Product = ({ data }) => {
  const [price, setPrice] = useState(5)
  const [cart, setCart] = useContext(CartContext)
  const [size, setSize] = useState(
    `${data.sanityProduct.defaultProductVariant.sizes[0].width}x${data.sanityProduct.defaultProductVariant.sizes[0].height}`
  )
  const [canvas, setCanvas] = useState(true)
  const [isFramed, setIsFramed] = useState(true)

  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }

  const addToCart = () => {
    let id = uuid()
    const product = {
      id: id,
      image:
        data.sanityProduct.defaultProductVariant.images.asset.fluid.srcWebp,
      name: data.sanityProduct.title,
      price: price,
    }
    setCart(currentState => [...currentState, product])
  }

  const sizeSelected = e => {
    setSize(e.target.value)
  }

  const typeCanvas = () => {
    setCanvas(true)
  }
  const typeFrame = () => {
    setCanvas(false)
  }

  let category = data.sanityProduct.categories[0].slug.current
  useEffect(() => {
    let canvasSize = size.split("x").map(num => parseInt(num, 10))
    let count = 1
    let pricePerSq = 40
    let biggerSide = 1

    // Is the product to big to be framed and shipped
    if (category === "jednodjelne-slike" && canvasSize[1] >= canvasSize[0]) {
      if (canvasSize[1] + canvasSize[0] * 2 < 290) {
        setIsFramed(true)
      } else if (canvasSize[1] + canvasSize[0] * 2 >= 290) {
        setIsFramed(false)
        setCanvas(true)
      }
    }
    if (category === "jednodjelne-slike" && canvasSize[0] > canvasSize[1]) {
      if (canvasSize[0] + canvasSize[1] * 2 < 290) {
        setIsFramed(true)
      } else if (canvasSize[0] + canvasSize[1] * 2 >= 290) {
        setIsFramed(false)
        setCanvas(true)
      }
    }

    // SetBiggerSize
    if (canvasSize[0] > canvasSize[1]) biggerSide = 0

    // Set price per sq meter
    if (category === "tapete") pricePerSq = 14
    else if (category === "jednodjelne-slike" && canvasSize[biggerSide] >= 180)
      pricePerSq = 35
    else if (category === "trodjelne-slike" && canvasSize[biggerSide] < 180)
      pricePerSq = 42
    else if (category === "trodjelne-slike" && canvasSize[biggerSide] >= 180)
      pricePerSq = 35
    else if (category === "cetverodjelne-slike" && canvasSize[biggerSide] < 100)
      pricePerSq = 45
    else if (
      category === "cetverodjelne-slike" &&
      canvasSize[biggerSide] >= 100
    )
      pricePerSq = 35
    else if (category === "petodjelne-slike") pricePerSq = 30
    else pricePerSq = 40

    // Set count
    if (category === "trodjelne-slike") count = 3
    else if (category === "cetverodjelne-slike") count = 4
    else if (category === "petodjelne-slike") count = 5
    else count = 1

    let total = calculatePrice(canvasSize[0], canvasSize[1], count, pricePerSq)

    if (canvas) {
      setPrice(Math.ceil(total.canvasCost))
    } else {
      setPrice(Math.ceil(total.total))
    }
    console.log(isFramed)
  }, [size, canvas, category, isFramed])

  return (
    <Layout>
      <SEO title={data.sanityProduct.title} />
      <ProductDetail>
        <img
          src={
            data.sanityProduct.defaultProductVariant.images.asset.fluid.srcWebp
          }
          alt={data.sanityProduct.title}
        />
        <div className="detail">
          <p className="category">
            {data.sanityProduct.categories[0].slug.current}
          </p>
          <h1>{data.sanityProduct.title}</h1>
          <h2 className="price">{`${price}€`}</h2>
          <h3>Izaberi veličinu:</h3>
          <select value={size} onChange={sizeSelected} className="sizes">
            {data.sanityProduct.defaultProductVariant.sizes.map(size => (
              <option
                key={`${size.width}x${size.height}`}
              >{`${size.width}x${size.height}`}</option>
            ))}
          </select>
          {data.sanityProduct.categories[0].slug.current !== "tapete" && (
            <>
              <h3>Izaberi opciju:</h3>
              <div className="type">
                <div>
                  <CanvasTypeButton
                    onClick={typeCanvas}
                    text={"Platno bez rama"}
                    url={"/images/platno.png"}
                    isSelected={canvas}
                  />
                  <p>Platno bez rama</p>
                </div>

                {isFramed && (
                  <div>
                    <CanvasTypeButton
                      onClick={typeFrame}
                      text={"Slika na ramu"}
                      url={"/images/ram.png"}
                      isSelected={!canvas}
                    />
                    <p>Slika na ramu</p>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="buttons">
            <button className="cart" onClick={addToCart}>
              <CartIcon />
              Dodaj u korpu
            </button>
            <button onClick={openModal} className="cart info">
              <InfoIcon />
              Uslovi kupovine
            </button>
          </div>
        </div>
        <Modal
          style={modalStyles}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <Terms onClick={closeModal} />
        </Modal>
      </ProductDetail>
    </Layout>
  )
}

export default Product

const modalStyles = {
  content: {
    margin: "0 auto",
    maxWidth: "960px",
  },
}

const ProductDetail = styled.section`
  color: #505050;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  img {
    max-width: 600px;
    width: 100%;
  }
  .detail {
    .category {
      color: #898989;
    }
    h1 {
      font-weight: 400;
      font-size: 32px;
    }
    h2 {
      font-size: 48px;
    }
    .sizes {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 30px;
    }
    .type {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      text-align: center;
    }
    .buttons {
      border-top: 2px solid #c9c9c9;
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      button {
        margin: 10px;
      }
    }
    .cart {
      background-color: #f05e5e;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      svg {
        fill: #fff;
        margin-right: 10px;
      }
    }
    .info {
      background-color: #74938d;
    }
    select {
      background-color: #fff;
      border: none;
      box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
      color: #505050;
      font-size: 18px;
      font-weight: bold;
      padding: 20px 40px;
    }
  }
`

export const productQuery = graphql`
  query product($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      title
      defaultProductVariant {
        sku
        sizes {
          height
          width
        }
        images {
          asset {
            fluid {
              srcWebp
            }
          }
        }
      }
      categories {
        slug {
          current
        }
        parents {
          slug {
            current
          }
        }
      }
    }
  }
`
