import React from "react"
import styled from "styled-components"

const CanvasTypeButton = ({ onClick, text, url, isSelected }) => (
  <Button
    url={url}
    onClick={onClick}
    aria-label={text}
    className={isSelected ? "canvas-type-selected" : ""}
  />
)

const Button = styled.button`
  background: url(${props => props.url});
  border: none;
  border-radius: 5px;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 150px;
  width: 150px;
  :focus,
  :active {
    box-shadow: 0 0 0 2px #505050, 8px 8px 16px rgba(0, 0, 0, 0.1);
  }
`

export default CanvasTypeButton
