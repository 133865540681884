export const calculatePrice = (width, height, count, pricePerSqMeter) => {
  let diameterPerCount = {
    width: width / count,
    height,
  }

  // calculate frame cost
  let diameter = (2 * (diameterPerCount.width + diameterPerCount.height)) / 100
  let frameCost = (diameter * 4 + 2.5) * count

  // calculate canvas cost
  let canvasCost =
    (((diameterPerCount.width + 7) * (diameterPerCount.height + 7) * count) /
      10000) *
    pricePerSqMeter

  let canvasWithInterest = canvasCost + canvasCost * 0.2 + 10

  const result = {
    frameCost,
    canvasCost: canvasWithInterest,
    total: frameCost + canvasWithInterest,
  }
  return result
}
