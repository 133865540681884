import React from "react"
import styled from "styled-components"

const Terms = ({ onClick }) => (
  <StyledTerms>
    <section>
      <h2>Uslovi kupovine</h2>
      <p>
        Prodavac je Štamparija Pale koji posluje putem web trgovine na adresi
        internet stranice:
        <a href="https://www.kupisliku.com"> https://www.kupisliku.com</a>.
      </p>
      <p>
        Kupac je posjetioc ovih stranica koji popuni elektronsku narudžbu,
        pošalje je prodavcu, te izvrši plaćanje putem kreditnih kartica.
      </p>
      <p>
        Kupcem se smatra svaka osoba koja elektronski naruči barem jedan
        proizvod, popuni tražene podatke i izvrši narudžbu.
      </p>
      <p>Kupovina se smatra zaključenom kada kupac dobije potvrdu narudžbe.</p>
    </section>
    <section>
      <h2>Cijena artikla</h2>
      <p>
        Sve cijene artikala su izražene u valuti evro, koji će biti preračunat
        po važećem kursu poslovne banke na dan uplate za uplate putem banke.
      </p>
      <p>
        Srednji kurs se uzima za obračun na dan otpreme robe za pošiljke po
        pouzeću.
      </p>
      <p>Cijene vrijede u trenutnku potvrde narudžbe.</p>
    </section>
    <section>
      <h2>Isporuka robe</h2>
      <p>
        Osigurana je isporuka robe na prostoru cijele teritorije BiH, Republike
        Srbije i Crne Gore.
      </p>
      <p>
        Uobičajeno vrijeme isporuke je 7-10 radnih dana od potvrde narudžbe.
      </p>
      <p>
        Robu koju prodajemo putem internet trgovine isporučujemo upakovanu,
        uključujući po potrebi dodatnu zaštitu od oštećenja i lomova u
        transportu.
      </p>
      <p>Roba se dostavlja kurirskom službom pošte.</p>
      <p>Po slanju pošiljke možete zahtijevati KOD pošiljke za praćenje. </p>
      <p>
        Naplata za otpremu i rukovanje ne zavisii od veličine i težine naručene
        robe već samo od narudžbe
      </p>
    </section>
    <section>
      <h2>Uslovi reklamacije</h2>
      <p>
        U roku od 30 dana od datuma isporuke kupac ima pravo uraditi povrat robe
        i tražiti povrat novca ili zamjenski artikl. Povrat robe će biti
        prihvaćen samo ako je roba vraćena neoštećena. Nastale troškove vezano
        za povrat robe plaća kupac.
      </p>
    </section>
    <button onClick={onClick}>Zatvori</button>
  </StyledTerms>
)

const StyledTerms = styled.div`
  margin: 0 auto;
  max-width: 960px;
  position: relative;
  z-index: 20;
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  button {
    background-color: #f05e5e;
    border: none;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    padding: 10px 20px;
    margin: 0 auto;
  }
`

export default Terms
